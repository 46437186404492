body {
  background: rgba(13,88,255);
  background-color: #3f3f3f;
}

.App {
  text-align: center;
  color: #fff;
}

@media only screen and (max-width: 700px) {
  .spacer {
    height: 5em;
  }
  .mini-spacer{
    height: .5em;
  }

  .wider {
    width: 50%;
  }

  .login-register Button {
    margin: 5px 25px;
    width: 70%;
  }
}

@media only screen and (min-width: 700px) {
  .spacer {
    height: 10em;
  }
  .explainer {
    width: 45%;
  }

  .mini-spacer{
    height: 2em;
  }

  .wider {
    width: 25%;
  }

  .login-register Button {
    margin: 25px;
    width: 25%;
  }

  .edit-button {
    width: 250px;
  }
}

.explainer{
  font-size: 1.3em;
  margin: auto;
}

.explainer-text{
  font-size: 0.9em;
  display: inline-block;
}

.second-explainer Input, .second-explainer p {
  width: 110px;
}

.explainer Input {
  margin-bottom: 10px;
}

.explainer Button {
  margin-top: 10px;
}

.background{
  background: url("./img/shaded-2.jpg");
  background-size: cover;
  background-repeat: none;
  z-index: -1;
  min-height: 105vh;
}

.cta, .cta-2 {
  display: inline-block;
  background-color: #ffe550 !important;
  padding: 10px;
  border-radius: 5px;
  width: 250px;
  margin: 10px;
  color: black !important;
}

.cta:hover, .cta-2:hover {
  background-color: #fded91 !important;
}

.cta a, .cta-2 a {
  text-decoration: none;
  color: black !important;
}

.cta a:hover, .cta-2 a:hover, .bubble a:hover {
  text-decoration: underline;
  color: rgb(66, 66, 66) !important;
}

.fade {
  background-image: linear-gradient(90deg, rgba(0,0,0,0) 0%, #fff 50%, rgba(13,88,255) 75%);
}

#navbar {
  color: #000;
}

.contact-list {
  list-style: none;
  padding: 0;
  padding: 0 30% 0 30%;
  text-align: left;
  /* display: inline; */
}

.contact-list li Form {
  text-align: center;
}

@media only screen and (min-width: 700px) {
  .register, .login {
    padding-left: 30%;
    padding-right: 30%;
  }

  .register Input, .login Input {
    margin: 1em;
  }
}
@media only screen and (max-width: 700px) {
  .register, .login {
    padding-left: 0%;
    padding-right: 0%;
  }

  .register Input, .login Input {
    margin-bottom: 1em;
  }

  .steps {
    margin: 1.5em;
  }
}

.contacts-row, .contacts-row td{
  margin: 5px;
}

@media only screen and (min-width: 700px) {
  .contacts-container{
    width: 80%;
    background-color: rgba(0,0,0,0.6);
    padding-top: 20px;
    border-radius: 15px;
    padding: 1.5em;
    margin: auto;
    min-height: 80vh;
  }
}

@media only screen and (max-width: 700px) {
  .contacts-container{
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    padding-top: 20px;
    border-radius: 15px;
    margin: auto;
  }
}

.tiny-spacer{
  padding: 5px;
}

.input-item{
  width: 48%;
  margin: 1%;
}

@media only screen and (min-width: 1000px) {
  .large-view{
    visibility: visible;
  }

  .small-view{
    display: none;
  }

  .contacts-spacer{
    height: 150px;
  }
}


@media only screen and (max-width: 1000px) {
  .large-view{
    display: none;
  }
  .small-view{
    visibility: visible;
    margin: 1em;
  }

  .contacts-spacer{
    height: 50px;
  }

  .thin-tall-line {
    display: none;
  }
}

.view-button{
  width: 80%;
  height: 3em;
  padding: 3em;
  color: rgba(13, 190, 200, 0.8);
  /* background-color: rgb */
}

.delete-left{
  float: left;
}

.black-floater{
  color: #3f3f3f;
}

.forgot {
  text-decoration: underline;
  font-weight: 720;
}

.forgot a, .link {
  color: rgb(140, 213, 255);
}

.delete:hover {
  text-decoration: underline;
}

.contacts-background {
  /* background-color: #ffe550; */
  background: url("./img/shaded-2.jpg");
  height: 100vh;
}

.edit-button {
  /* background-color: #ffe550 !important;
  border: none !important; */
}

.extra-padding {
  padding: 0 5%;
}

footer a {
  color: rgb(140, 213, 255);
  text-decoration: underline;
}

.link {
  background-color: rgba(0,0,0,0);
  border: none;
}

.link:hover {
  color: rgb(180, 227, 255); 
}

#shitty-button {
  margin-left: -6px;
}

.header {
  font-size: 2.5em;
  font-weight: 800;
}

.step-container {
  padding-bottom: 20px;
}

.thin-tall-line {
  height: 200px;
  background-color: #0f0f0f;
  width: 2px;
}

.value-prop, .steps {
  margin: 10px;
}

.separator, .separator-2, .separator-3 {
  height: 15px;
  width: 100%;
}

.separator {
  background-color: #ffe550;
}

.inner, .inner-two {
  margin: 0 10% 0 10%;
  background-color: rgba(0,0,0,0.8);
  padding: 5%;
  border-radius: 15px;
  color: white;
}

.inner-two, .mid {
  background-color: rgba(255,255,255,1);
  color: #000;
}

.bubble {
  background-color: rgba(253, 237, 145, 0.8);
  color: black;
  border-radius: 5px;
  padding: 1.2em;
  text-align: center;
}

.bubble a {
  color: rgb(82, 82, 82);
}

.blank-link {
  color: white;
  text-decoration: underline;
}

.blank-link:hover {
  color: rgb(206, 206, 206);
  text-decoration: none;
}